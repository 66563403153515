// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  branch: 'master',
  environment: 'production',
  googleMapsAPIKey: 'AIzaSyCPJ1gaUcqzGoi2wzMOS7AbWzO4N1O_RBg',
  fullcalendarLicenseKey: '0202134901-fcs-1712845796',
  sentry: {
    dns: 'https://09dd5b9819b94299a7deceab4e05bdb2@o1130118.ingest.sentry.io/6174068',
    traceSampleRate: 0.1
  },
  auth0: {
    client_id: 'DfT51u6y03YyBEZcT4EOuAbkJFdAUO4j',
    domain: 'sympliact-prod.auth0.com',
    responseType: 'token id_token',
    logout_url: 'https://portal.sympliact.com/auth/login',
    redirect_uri: 'https://portal.sympliact.com/callback',
    audience: 'https://sympliact-prod.auth0.com/api/v2/',
    scope: 'openid'
  },
  domainUrl: 'https://api.sympliact.com',
  baseUrl: 'https://api.sympliact.com/api/v1/prac/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
